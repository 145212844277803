// @flow

import React from 'react'
import type { Node } from 'react'

import { graphql, useStaticQuery } from 'gatsby'
import { CreateListing } from '../../scenes/listing'

import { getFormattedData } from '../../helpers'
import { coconutOverview } from '../../data/cottages-content/coconut'

import type {
  CottagePageContent,
  LegacyImage,
  OverviewItem,
  Image,
} from '../../types'

type QueryResponse = {|
  coconutCottagePage: CottagePageContent,
|}

type CoconutCottagePagePureProps = {
  data: {
    overview: OverviewItem[],
    cottageImages: LegacyImage[],
    heroImage: Image,
  },
}

function CoconutCottagePagePure({
  data: { overview, cottageImages, heroImage },
}: CoconutCottagePagePureProps) {
  return (
    <CreateListing
      id="coconut-cottage"
      cottageName="Coconut"
      featuredImageUrl={heroImage.src}
      overview={overview}
      welcomeMessage="Welcome to the Coconut Cottage"
      images={cottageImages}
    />
  )
}

function CoconutCottagePage(): Node {
  const data: QueryResponse = useStaticQuery(graphql`
    query CoconutCottagePageQuery {
      coconutCottagePage: contentfulCottagePage(title: { eq: "Coconut" }) {
        id
        title
        featuredImage {
          id
          title
          description
          image: resize(width: 1800, quality: 80) {
            src
          }
        }
        cottageImages {
          id
          description
          image: resize(width: 800, quality: 80) {
            src
          }
        }
      }
    }
  `)

  return (
    <CoconutCottagePagePure
      data={{
        ...getFormattedData(data.coconutCottagePage),
        overview: coconutOverview,
      }}
    />
  )
}

export default CoconutCottagePage
